import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import SanityLink from '~components/SanityLink'
import { LinkedIn, Instagram } from '~components/Svg'
import { mobile, tablet } from '~styles/global'


const FooterMenuItem = ({ className, key, link, children }) => {

	const [hover, setHover] = useState(false)

	return (
		<GradientLinkWrap
			className={className} key={key} 
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<GradientLink  link={link} >
				<Relative onMouseEnter={() => setHover(true)}>
					<Text hover={hover}>
						{children}
					</Text>
					<Overlay hover={hover}>
						{children}
					</Overlay>
					<HiddenText hover={hover}>
						{children}
					</HiddenText>
				</Relative>
			</GradientLink>
		</GradientLinkWrap>
	)
}

const GradientLinkWrap = styled.div`
	margin-right: 40px;
	${tablet}{
		width: 100%;
	}
`
const GradientLink = styled(SanityLink)`
	font-weight: 400;
	position: relative;
	:after{
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: var(--darkGrey);
		height: 1px;
		${tablet}{
			display: none;
		}
	}
`
const Overlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: -1;
	background: linear-gradient(to right, #0182C4 0%, #1BB28E 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	opacity: ${ props => props.hover ? '1' : '0'};
	transition: opacity 0.25s;
`
const Relative = styled.div`
	position: relative;
	z-index: 2;
`
const Text = styled.div`
	opacity: ${ props => props.hover ? '0' : '1'};
	transition: opacity 0.25s;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 1;
`
const HiddenText = styled.div`
	opacity: 0;
`

const Footer = ({ className, white  }) => {

	const { footerMenu, linkedIn, instagram } = useSiteSettings()

	return(
		<Wrap className={className}>
			<Inner>
				<ColDesk>
					<div>
						<p className='small'>
							<Line>© Copyright {new Date().getFullYear()} Cube Finance Group <br /></Line>
							<Line>Design by <a href="https://duganosullivan.com.au/" target='_blank' rel="noreferrer">D&O</a>. Build by <a href="https://formwork.build/" target='_blank' rel="noreferrer">Formwork</a></Line>
						</p>
					</div>
				</ColDesk>
				<Col>
					{footerMenu.map(menuItem => (
						<FooterMenuItem key={menuItem._key} link={menuItem.link} className='small'>
							{menuItem.text}
						</FooterMenuItem>
					))}
					<LinkedInLinkDesk link={{ linkType: 'external', url: linkedIn, blank: true}}>
						<LinkedIn />
					</LinkedInLinkDesk>
				</Col>
				<ColMobile>
					<LinkedInLink link={{ linkType: 'external', url: linkedIn, blank: true}}>
						<LinkedIn />
					</LinkedInLink>
					{/* <InstaLink link={{ linkType: 'external', url: instagram, blank: true}}>
						<Instagram />
					</InstaLink> */}
					<MobileLine className='small'>© Copyright {new Date().getFullYear()} </MobileLine>
					<MobileLine className='small'>Cube Finance Group</MobileLine>
					<MobileLine className='small'>Design by <a href="https://duganosullivan.com.au/" target='_blank' rel="noreferrer">D&O</a></MobileLine>
					<MobileLine className='small'>Build by <a href="https://formwork.build/" target='_blank' rel="noreferrer">Formwork</a></MobileLine>
				</ColMobile>
			</Inner>
			<Disclaimer>
				<div className='small'>
				Cube Finance Group Pty Ltd (ABN 82 143 883 738 / Credit Representative Number 392953) has access to a panel of lenders through National Mortgage Brokers Pty Ltd., (ACN 093 874 376 / Australian Credit Licence 391209), which is a fully-owned subsidiary of Liberty Financial Pty Ltd (ACN 077 248 983 / Australian Credit Licence 286596). Cube Finance Group Pty Ltd has access to products including those from Liberty Financial.
				</div>
			</Disclaimer>
			<Bottom/>
		</Wrap>
	)
}

const Wrap = styled.footer`
	border-color: ${ props => props.white ? 'var(--white)' : 'var(--extraDarkGrey)'};
	border-top: 1px solid;
`
const Inner = styled(Section)`
	> div {
		padding: 0 18%;
		height: 116px;
		${tablet}{
			padding: 0 20px;
			height: 123px;
		}
	}
	${tablet}{
		border-bottom: 1px solid;
	}
`
const Col = styled.div`
	grid-column: span 6;
	display: flex;
	align-self: center;
	justify-content: center;
	align-items: center;
	min-height: 21px;
	${tablet}{
		flex-wrap: wrap;
	}
`
const LinkedInLink = styled(SanityLink)`
	width: 21px;
	display: inline-block;
	/* margin-right: 6px; */
	${tablet}{
		margin-bottom: 6px;
	}
`
const LinkedInLinkDesk = styled(LinkedInLink)`
	${tablet}{
		display: none;
	}
`
const InstaLink = styled(SanityLink)`
	display: inline-block;
	width: 22px;
	margin-bottom: 6px;
`
const MobileLine = styled.span`
	display: block;
`
const ColMobile = styled(Col)`
	display: none;
	${tablet}{
		display: block;
		text-align: right;
	}
`
const ColDesk = styled(Col)`
	${tablet}{
		display: none;
	}
`
const Line = styled.div`
	margin-bottom: 6px;
`
const Bottom = styled.div`
	display: none;
	${tablet}{
		display: block;
		height: 79px;
	}
`

const Disclaimer = styled.div`
	grid-column: span 12;
	padding: 0 18%;
	height: 116px;
	display: flex;
	align-items: center;
	background: var(--grey);
	border-top: 1px solid;
	${tablet}{
		padding: 20px 20px;
		border-bottom: 1px solid;
		border-top: none;
		height: auto;
	}
`

Footer.propTypes = {
	className: PropTypes.string,
	white: PropTypes.bool
}
FooterMenuItem.propTypes = {
	className: PropTypes.string,
	key: PropTypes.string,
	link: PropTypes.object,
	children: PropTypes.node,
}

export default Footer
